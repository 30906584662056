
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarFinanceReports from "@/components/sidebar-finance-reports.vue";
import ReportService from "@/services/reports-service";
import accountService from "@/services/account-service";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import ExportOptions from "@/components/export-options.vue";
import FilterService from "@/services/filter-service";

@Component({
  components: {
    Header,
    SidebarFinanceReports,
    FilterComponent,
    ExportOptions
  },
})
export default class StatementsOfAccounts extends Vue {
  private data: any[] = [];
  public filter = null;
  public filterOn = [];
  trans = [];
  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";
  selected_report_date_from: any = "";
  selected_report_date_to: any = "";
  public options_account_from = [];
  public options_account_to = [];

  public account_selected_from: any = {};
  public account_selected_to: any = {};

  side_tran_entry: any = [];
  side_tran: any = {};
  get rows() {
    return this.selected_items.length;
  }
  public items = [];
  selection = 'all';
  public fields = [
    {
      key: "transaction",
      label: "Voucher No.",
    },
    {
      key: "date",
    },

    {
      key: "description",
      thClass: "description-cell",
      tdClass: "description-cell",
    },
    // {
    //   key: "account_title",
    // },
    // {
    //   key: "account_code",
    // },
    {
      key: "cost_center",
    },
    {
      key: "debit",
    },
    {
      key: "credit",
    },
    {
      key: "balance",
    },
    {
      key: "b_type",
      label: "Type",
    },
    {
      key: "po_no",
    },
    {
      key: "request_no",
    },
    {
      key: "description",
    },
  ];
  public columns = [
    { label: "Voucher No.", field: "transaction" },
    { label: "Account Code", field: "account_code" },
    { label: "Account Title", field: "account_title" },
    { label: "Date", field: "date" },
    { label: "Description", field: "description" },
    { label: "Cost Center", field: "cost_center" },
    { label: "Debit", field: "debit" },
    { label: "Credit", field: "credit" },
    { label: "Balance", field: "balance" },
    { label: "Type", field: "b_type" },
    { label: "PO No.", field: "po_no" },
    { label: "Request No.", field: "request_no" },

  ];
  public selected_items = [
    {
      account_title: "",
      debit: 0,
      credit: 0,
      balance: "",
      date: "",
    },
  ];

  retrieve(id: any) {
    this.selected_items = [];
    ReportService.getledger(id)
      .then((response) => {
        if (response.data.result.length > 0) {
          this.selected_items = response.data.result;
          // console.log(response.data.result);
        } else {
          this.selected_items.push({
            account_title: "No Record Found",
            date: "",
            debit: 0,
            credit: 0,
            balance: "",
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  mounted() {
    // accountService.getAccounts()
    // .then((response) => {
    //   this.options_account = response.data.result;
    //   })
    // .catch((e) => {
    //   console.log(e);
    // });
  }

  submit() {
    let obj = {
      start_date: this.selected_report_date_from,
      end_date: this.selected_report_date_to,
      account_from: this.account_selected_from.code,
      account_to: this.account_selected_to.code,
    };
    this.retrieve(obj);
    // if (this.selection === "all") {
    //   let obj = {
    //     start_date: this.selected_report_date_from,
    //     end_date: this.selected_report_date_to,
    //     // project: this.account_selected_from.id,
    //   };
    //   this.retrieve(obj);
    // } else if (this.selection === "particular") {
    //   // let obj = {
    //   //   start_date: this.selected_report_date_from,
    //   //   end_date: this.selected_report_date_to,
    //   //   account_from: this.account_selected_from.code,
    //   //   account_to: this.account_selected_to.code,
    //   // };
    //   // this.retrieve(obj);
    // }
  }

  onRowClicked(item: any, index: any, event: any) {
    ReportService.get_transaction_id(item.transaction_id)
      .then((response) => {
        this.side_tran = response.data.transaction[0];
        this.side_tran_entry = response.data.entry;
      })
      .catch((e) => {
        console.log(e);
      });
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant");
  }

  get_parent_account(data: any, type: string) {
    if (type == "from") {
      this.account_selected_from = data;
      this.options_account_from = [];
    } else if (type == "to") {
      this.account_selected_to = data;
      this.options_account_to = [];
    }
  }

  search_parent_account(title: any, type: string) {
    if (title.length > 2) {
      accountService
        .getByTitleMaster(title)
        .then((response) => {
          if (type == "from") {
            this.options_account_from = response.data.result;
          } else if (type == "to") {
            this.options_account_to = response.data.result;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.options_account_from = [];
      this.options_account_to = [];
    }
  }
  advance_filter_fields = [
    { type: "text", label: "Voucher No.", name: "voucher#", value: "", param_name: "transaction", filter_type: "advance", },
    { type: "text", label: "Account Code", name: "account_code", value: "", param_name: "account_code", filter_type: "advance", },
    { type: "text", label: "Account Title", name: "account_title", value: "", param_name: "account_title", filter_type: "advance", },
    { type: "text", label: "Date", name: "date", value: "", param_name: "date", filter_type: "advance", },
    { type: "text", label: "Cost Center", name: "cost_center", value: "", param_name: "cost_center", filter_type: "advance", },
    { type: "text", label: "Debit", name: "debit", value: "", param_name: "debit", filter_type: "advance", },
    { type: "text", label: "Credit", name: "credit", value: "", param_name: "credit", filter_type: "advance", },
    { type: "text", label: "Balance", name: "balance", value: "", param_name: "invoice_id", filter_type: "advance", },
    { type: "dropdown", label: "Type", name: "type", value: "", param_name: "t_type", options: ['Debit', 'Credit'], filter_type: "advance", },
    { type: "text", label: "PO No.", name: "po#", value: "", param_name: "po_no", filter_type: "advance", },
    { type: "text", label: "Request No.", name: "request#", value: "", param_name: "request_no", filter_type: "advance", },

  ];
  handleFilterChange(element: any) {
    if (element.filter_type === "advance") {
      interface AdvanceObj {
        [key: string]: any;
      }
      const advanceFilteredObj: AdvanceObj = {};
      this.advance_filter_fields.forEach((filter) => {
        if (filter.type === "text") {
          if (filter.param_name) {
            advanceFilteredObj[filter.param_name] = filter.value;
          }
        }
      });
      FilterService.getStatementOfAccount(advanceFilteredObj).then((data) => {
        // this.selected_items = data.data.transaction_entry;
        // console.log(data)
        if (data.data.transaction_entry !== undefined && data.data.transaction_entry !== null) {
          this.selected_items = data.data.transaction_entry;
        } else {
          this.submit();
        }
      });
    }
  }
  refreshAdvanceFilters() {
    // for (let field of this.filter_fields) {
    //   field.value = "";
    //   this.submit();
    // }
    for (let field of this.advance_filter_fields) {
      if (field.type == "text" || field.type == "dropdown") {
        field.value = "";
      }
      // else if (field.type == "date") {
      //   field.range = [];
      // }
      this.submit();
    }
  }
  refreshFields() {
    this.account_selected_from = {};
    this.account_selected_to = {};
    this.selected_report_date_from = "";
    this.selected_report_date_to = "";
  }
}
